<template>
  <section class="content-container">
    <SectionTabs :tabs="tab_component"/>
    <router-view/>
  </section>
</template>

<script>
  import SectionTabs from "@/components/Section/SectionTabs.vue";
  import { mapActions, mapState } from "vuex";

  export default {
    components: {
      SectionTabs,
    },
    data() {
      return {
        title: "Beneficios",
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        show_calendar: false,
        calendar_side: "",
        types: [],
      };
    },
    mounted() {
      this.getType();
    },
    computed: {
      ...mapState("benefitModule", ["tab_component"]),
      attributes() {
        return {
          highlight: true,
          color: "#009CDE",
          dates: new Date(),
        };
      },
    },
    methods: {
      ...mapActions("benefitModule", ["getTipoDeSolicitudes"]),

      goTo(route) {
        var current_route = this.$route.name;
        //console.log("rev: ",current_route);
        if (route == current_route) {
          return;
        } else {
          this.$router.push({ name: route });
        }
      },
      activeTab(tab) {
        //console.log("rev: ",current_route);

        var current_route = this.$route.name;
        if (tab == current_route) {
          return "active";
        }
      },
      async getType() {
        let data = {
          id_empresa: this.id_empresa,
        }
        await this.getTipoDeSolicitudes(data).then(resp=>{
          if(resp==null){
            console.log("mensaje de falla");
          }
        });
        
      },
    },
  };
</script>
